:root {
  --app-height: 100%;
  --app-width: 100%;
}

html, body, #root {
  padding: 0;
  margin: 0;
  width: 100vw;
  width: var(--app-width);
  /*height: 100vh;*/
  height: 100vh;
  height: var(--app-height);
  overflow: hidden;
}

.scroll-pane {
  overflow: scroll;
  height: 100%;
  /*height: var(--app-height);*/
  max-width: 100%;
  /*scrollbar-color: blue #f1f1f1;*/
}



/*::-webkit-scrollbar {*/
/*  */
/*}*/

body, .light-theme {
/*, .light-theme 02a8a8*/
/*  background-color: rgba(27, 94, 32, 0.40);*/
  background-color: rgba(230, 81, 0, 0.40);
/*  background-color: #01c2c2;*/
  /*background-color: #005656;*/
}

body, .dark-theme {
  /*background-color: #013f3f;*/
/*rgb(230, 81, 0)*/
  background-color: rgb(230, 81, 0);
}

/*body, .light-theme, .staging {*/
/*    !*background-color: #013f3f;*!*/
/*    background-color: rgba(230, 0, 0, 0.4);*/
/*}*/

/*body, .dark-theme, .staging {*/
/*  !*background-color: #013f3f;*!*/
/*  background-color: rgba(236, 39, 39, 0.4);*/
/*}*/